
import { setSubmitting } from '@/Chat/module';
import { defineComponent } from '@vue/runtime-core';
import Chat from '../../Chat/index.vue';
import { reset as chooserReset } from '../Chooser/module';

export default defineComponent({
    components: { Chat },
    methods: {
        initiateSubmit(){
            setSubmitting();
            chooserReset();
            setTimeout(() => {
                this.$router.push('/questions');
                setSubmitting();
            }, 800);
        }
    }
});
