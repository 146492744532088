<template>
    <chat
        :initiateSubmit='initiateSubmit'
        fallbackRoute='/questions'
    >
        <template #top>
            <div id='topLegend' class='card'>
                השאלון מוצג כאן כפי שהיה נראה למטופל שממלא את אותן השאלות
            </div>
        </template>
    </chat>
</template>

<script lang='ts'>
import { setSubmitting } from '@/Chat/module';
import { defineComponent } from '@vue/runtime-core';
import Chat from '../../Chat/index.vue';
import { reset as chooserReset } from '../Chooser/module';

export default defineComponent({
    components: { Chat },
    methods: {
        initiateSubmit(){
            setSubmitting();
            chooserReset();
            setTimeout(() => {
                this.$router.push('/questions');
                setSubmitting();
            }, 800);
        }
    }
});
</script>

<style lang="scss" scoped>
#topLegend {
    width: 26rem;
    font-size: 1.1rem;
    padding: 1rem 1.5rem;
    text-align: center;
    box-shadow: 1px 1px .5rem .4rem #ddd;
    margin: -.8rem auto;
    font-weight: 500;
}
</style>
